<template>
    <div :class="['answer', messageFrom]">
        <div :class="['avatar']">
            {{ avatar }}
        </div>
        <div class="details">
            <div class="name">{{ name }}</div>
            <div class="time">{{ item.date }}</div>
        </div>
        <div class="text" v-html="decodedMessage"></div>
    </div>
</template>

<script>
import { sync } from 'vuex-pathify';
import { htmlDecode } from '@/utils/index';

export default {
    props: ['item'],
    data: () => ({}),
    computed: {
        affiliate_name: sync('user/info@name'),
        name() {
            if (this.item.fromClient == 1) {
                if (this.item.name) {
                    return this.item.name;
                } else {
                    return 'Client';
                }
            } else {
                return this.affiliate_name;
            }
        },
        avatar() {
            let exploded = this.name.split(' ');
            let avatar = exploded[0].charAt(0);
            if (exploded[1]) {
                avatar += exploded[1].charAt(0);
            }
            return avatar;
        },
        messageFrom() {
            return this.item.fromClient == 1 ? 'left' : 'right';
        },
        decodedMessage() {
            return htmlDecode(this.item.message);
        },
    },
};
</script>

<style lang="scss" scoped>
.avatar {
    bottom: 0;
    width: 40px;
    height: 40px;
    position: absolute;
    background: #f0e9e9;
    color: #8b5d5d;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 18px;
}

.details {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.details .name {
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.details .time {
    font-size: 12px;
    color: grey;
}

/*****************CHAT BODY *******************/
h6 {
    font-size: 20px;
    margin: 0 0 20px;
}

.answer.left {
    padding: 0 0 0 58px;
    text-align: left;
    float: left;
}

.answer {
    position: relative;
    width: 100%;
    max-width: 600px;
    overflow: hidden;
    clear: both;
    margin-bottom: 20px;
}

.answer.left .avatar {
    left: 0;
}

.answer .avatar {
    bottom: 0;
}

.avatar {
    width: 40px;
    height: 40px;
    position: absolute;
}

.avatar img {
    display: block;
    border-radius: 20px;
    height: 100%;
}

.answer .name {
    font-size: 14px;
    line-height: 36px;
}

.answer.left .text {
    background: #ebebeb;
    color: #333333;
    border-radius: 8px 8px 8px 0;
}

.answer .text {
    padding: 12px;
    font-size: 14px;
    line-height: 26px;
    position: relative;
}

.answer.left .text:before {
    left: -30px;
    border-right-color: #ebebeb;
    border-right-width: 12px;
}

.answer .text:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    border: 18px solid transparent;
    border-bottom-width: 0;
}

/*RIGHT*/
.answer.right {
    padding: 0 58px 0 0;
    text-align: left;
    float: right;
}

.answer.right .avatar {
    right: 0;
}

.answer.right .avatar .status {
    left: 4px;
}

.answer.right .text {
    background: #ff804a;
    color: #ffffff;
    border-radius: 8px 8px 0 8px;
}

.answer.right .text:before {
    right: -30px;
    border-left-color: #ff804a;
    border-left-width: 12px;
}

::v-deep .answer:last-child {
    margin-bottom: 10px;
}
</style>
